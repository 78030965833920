import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';


@Component({
  selector: 'app-oeracunima',
  templateUrl: './oeracunima.component.html',
  styleUrls: ['./oeracunima.component.css']
})

  
export class OeracunimaComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 730, behavior: 'smooth'});
    }
  }

}
