import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-obrt-slike',
  templateUrl: './obrt-slike.component.html',
  styleUrls: ['./obrt-slike.component.css']
})
export class ObrtSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('ispis', {static: false}) IspisProp: ElementRef;
  @ViewChild('primizd', {static: false}) PrimizdProp: ElementRef;
  @ViewChild('rekap', {static: false}) RekapProp: ElementRef;
  @ViewChild('pdv', {static: false}) PdvProp: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToPrimizd(): void {
    this.PrimizdProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIspis(): void {
    this.IspisProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToRekap(): void {
    this.RekapProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPdv(): void {
    this.PdvProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
