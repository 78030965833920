import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-izvozs',
  templateUrl: './izvozs.component.html',
  styleUrls: ['./izvozs.component.css']
})
export class IzvozsComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Izvoz i ispis izveštaja, Crystal Reports, Adacco programi ');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Izvoz i ispis izveštaja, Crystal Reports, software, Adacco'},
      {name: 'description', content: 'Izvoz i ispis izveštaja, Crystal Reports, vrhunski softver'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 630, behavior: 'smooth'});
    }

  }

}
