import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Dekl} from '../../dekl.service';
import {MessageComponentService} from '../../message.component.service';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-fisk1',
  templateUrl: './fisk1.component.html',
  styleUrls: ['./fisk1.component.css']
})
export class Fisk1Component implements OnInit {
  globv = environment;

  @ViewChild('naslovFisk1', {static: false}) Fisk1Prop: ElementRef;
  @ViewChild('naslovFisk2', {static: false}) Fisk2rop: ElementRef;

  constructor(
    private messageService: MessageComponentService,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.Fisk1Prop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setTimeout(() => this.gasiheader(), 1000);

    this.titleService.setTitle('Fiskalizacija u Adacco programima');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Fiskalizacija, Knjigovodstveni programi, software, Adacco, programi za knjigovodstvo'},
      {name: 'description', content: 'Fiskalizacija u Adacco programima'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }

}
