import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Ng2OrderModule } from 'ng2-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';
import { LoginBarComponent } from './_layout/login-bar/login-bar.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { SiteFooterComponent } from './_layout/site-footer/site-footer.component';
import { LoginBarsComponent } from './_layouts/login-bars/login-bars.component';
import { SiteLayoutsComponent } from './_layouts/site-layouts/site-layouts.component';
import { SiteFootersComponent } from './_layouts/site-footers/site-footers.component';
import { SiteHeadersComponent } from './_layouts/site-headers/site-headers.component';
import { HomeComponent } from './home/home.component';
import { HomesComponent } from './homes/homes.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { MainComponent } from './modules/main/main.component';
import { TopSideComponent } from './modules/top-side/top-side.component';
import { SocialSharingBarComponent } from './social-sharing-bar/social-sharing-bar.component';
import { OnamaComponent } from './onama/onama.component';
import { OnamadComponent } from './onama/onamad/onamad.component';
import { KontaktComponent } from './onama/kontakt/kontakt.component';
import { TestvodicComponent } from './onama/testvodic/testvodic.component';
import { VodiciComponent } from './vodici/vodici.component';
import { FinComponent } from './vodici/fin/fin.component';
import { MrezniradComponent } from './mreznirad/mreznirad.component';

import { CjenikComponent } from './cjenik/cjenik.component';
import { EconomicComponent } from './cjenik/economic/economic.component';
import { GcjenikComponent } from './cjenik/gcjenik/gcjenik.component';
import { KprostorComponent } from './cjenik/kprostor/kprostor.component';
import { ProfrentComponent } from './cjenik/profrent/profrent.component';
import { ProstorComponent } from './cjenik/prostor/prostor.component';

import { FinancComponent } from './modules/financ/financ.component';
import { FinVodicComponent } from './modules/fin-vodic/fin-vodic.component';
import { PlaComponent } from './modules/pla/pla.component';
import { PlaVodicComponent } from './modules/pla-vodic/pla-vodic.component';
import { MatComponent } from './modules/mat/mat.component';
import { MatVodicComponent } from './modules/mat-vodic/mat-vodic.component';
import { ProizVodicComponent} from './modules/proiz-vodic/proiz-vodic.component';
import { MatSlikeComponent } from './modules/mat-slike/mat-slike.component';
import { PlaSlikeComponent } from './modules/pla-slike/pla-slike.component';
import { FinSlikeComponent } from './modules/fin-slike/fin-slike.component';
import { RobComponent } from './modules/rob/rob.component';
import { MaloprComponent } from './modules/malopr/malopr.component';
import { ObrtComponent } from './modules/obrt/obrt.component';
import { OsnsrComponent } from './modules/osnsr/osnsr.component';
import { BlagComponent } from './modules/blag/blag.component';
import { PutnComponent } from './modules/putn/putn.component';
import { VirmComponent } from './modules/virm/virm.component';
import { RobVodicComponent } from './modules/rob-vodic/rob-vodic.component';
import { RobSlikeComponent } from './modules/rob-slike/rob-slike.component';
import { MaloprVodicComponent } from './modules/malopr-vodic/malopr-vodic.component';
import { UgostVodicComponent } from './modules/ugost-vodic/ugost-vodic.component';
import { PosVodicComponent } from './modules/pos-vodic/pos-vodic.component';
import { MaloprSlikeComponent } from './modules/malopr-slike/malopr-slike.component';
import { ObrtSlikeComponent } from './modules/obrt-slike/obrt-slike.component';
import { OsnsrVodicComponent } from './modules/osnsr-vodic/osnsr-vodic.component';
import { OsnsrSlikeComponent } from './modules/osnsr-slike/osnsr-slike.component';
import { BlagSlikeComponent } from './modules/blag-slike/blag-slike.component';
import { PutnSlikeComponent } from './modules/putn-slike/putn-slike.component';
import { VirmSlikeComponent } from './modules/virm-slike/virm-slike.component';
import { Fisk1Component } from './modules/fisk1/fisk1.component';
import { Fisk2Component } from './modules/fisk2/fisk2.component';
import { Fisk3Component } from './modules/fisk3/fisk3.component';
import { ModulesComponent } from './modules/modules.component';
import { SvojstvaComponent } from './svojstva/svojstva.component';
import { KarakComponent } from './svojstva/karak/karak.component';
import { PitodgComponent } from './svojstva/pitodg/pitodg.component';
import { ZanimljComponent } from './svojstva/zanimlj/zanimlj.component';
import { OsamzabludaComponent } from './svojstva/osamzabluda/osamzabluda.component';
import { IzvozComponent } from './svojstva/izvoz/izvoz.component';
import { CloudComponent } from './svojstva/cloud/cloud.component';
import { MongoComponent } from './svojstva/mongo/mongo.component';
import { LokserverComponent } from './svojstva/lokserver/lokserver.component';

import { InstalacijaComponent } from './instalacija/instalacija.component';
import { GinstalacijaComponent } from './instalacija/ginstalacija/ginstalacija.component';
import { SqlserverComponent } from './instalacija/sqlserver/sqlserver.component';
import { UpgradeComponent } from './instalacija/upgrade/upgrade.component';
import { UputstvoComponent } from './instalacija/uputstvo/uputstvo.component';

import { MrezniradsComponent } from './mreznirads/mreznirads.component';
import { CenovnikComponent } from './cenovnik/cenovnik.component';
import { GcenovnikComponent } from './cenovnik/gcenovnik/gcenovnik.component';
import { KprostorsComponent } from './cenovnik/kprostors/kprostors.component';
import { ProstorsComponent } from './cenovnik/prostors/prostors.component';
import { EconomicsComponent } from './cenovnik/economics/economics.component';

import { MainsComponent } from './moduoless/mains/mains.component';
import { FinancsComponent } from './moduoless/financs/financs.component';
import { LayoutsComponent } from './moduoless/layouts/layouts.component';
import { TopSidesComponent } from './moduoless/top-sides/top-sides.component';
import { PlasComponent } from './moduoless/plas/plas.component';
import { MatsComponent } from './moduoless/mats/mats.component';
import { RobsComponent } from './moduoless/robs/robs.component';
import { MaloprsComponent } from './moduoless/maloprs/maloprs.component';
import { VirmsComponent } from './moduoless/virms/virms.component';
import { BlagsComponent } from './moduoless/blags/blags.component';
import { OsnsrsComponent } from './moduoless/osnsrs/osnsrs.component';
import { FinsSlikeComponent } from './moduoless/fins-slike/fins-slike.component';
import { FinsVodicComponent } from './moduoless/fins-vodic/fins-vodic.component';
import { PlasSlikeComponent } from './moduoless/plas-slike/plas-slike.component';
import { PlasVodicComponent } from './moduoless/plas-vodic/plas-vodic.component';
import { MatsSlikeComponent } from './moduoless/mats-slike/mats-slike.component';
import { MatsVodicComponent } from './moduoless/mats-vodic/mats-vodic.component';
import { ProizsVodicComponent } from './moduoless/proizs-vodic/proizs-vodic.component';
import { RobsSlikeComponent } from './moduoless/robs-slike/robs-slike.component';
import { RobsVodicComponent } from './moduoless/robs-vodic/robs-vodic.component';
import { MaloprsSlikeComponent } from './moduoless/maloprs-slike/maloprs-slike.component';
import { MaloprsVodicComponent } from './moduoless/maloprs-vodic/maloprs-vodic.component';
import { OsnsrsSlikeComponent } from './moduoless/osnsrs-slike/osnsrs-slike.component';
import { OsnsrsVodicComponent } from './moduoless/osnsrs-vodic/osnsrs-vodic.component';
import { BlagsSlikeComponent } from './moduoless/blags-slike/blags-slike.component';
import { VirmsSlikeComponent } from './moduoless/virms-slike/virms-slike.component';
import { DrugprihComponent } from './moduoless/drugprih/drugprih.component';

import { SvojstvasComponent } from './svojstvas/svojstvas.component';
import { IzvozsComponent } from './svojstvas/izvozs/izvozs.component';
import { KaraksComponent } from './svojstvas/karaks/karaks.component';
import { OsamzabludasComponent } from './svojstvas/osamzabludas/osamzabludas.component';
import { PitodgsComponent } from './svojstvas/pitodgs/pitodgs.component';
import { ZanimljsComponent } from './svojstvas/zanimljs/zanimljs.component';
import { CloudsComponent } from './svojstvas/clouds/clouds.component';
import { LokserversComponent } from './svojstvas/lokservers/lokservers.component';
import { MongosComponent } from './svojstvas/mongos/mongos.component';

import { InstalacijasComponent } from './instalacijas/instalacijas.component';
import { GinstalacijasComponent } from './instalacijas/ginstalacijas/ginstalacijas.component';
import { UpgradesComponent } from './instalacijas/upgrades/upgrades.component';
import { UputstvosComponent } from './instalacijas/uputstvos/uputstvos.component';

import { OnamasComponent } from './onamas/onamas.component';
import { OnamadsComponent } from './onamas/onamads/onamads.component';
import { KontaktsComponent } from './onamas/kontakts/kontakts.component';

import { StesearchsComponent } from './stesearchs/stesearchs.component';
import { TrazimComponent } from './trazim/trazim.component';
import { SitesearchComponent } from './sitesearch/sitesearch.component';
import { EfakturesComponent } from './efaktures/efaktures.component';
import { ApikljucComponent } from './efaktures/apikljuc/apikljuc.component';
import { OefakturamaComponent } from './efaktures/oefakturama/oefakturama.component';
import { PristupComponent } from './efaktures/pristup/pristup.component';
import { AdaccoefaktComponent } from './efaktures/adaccoefakt/adaccoefakt.component';
import { EuriComponent } from './svojstva/euri/euri.component';
import { EracuniComponent } from './eracuni/eracuni.component';
import { OeracunimaComponent } from './eracuni/oeracunima/oeracunima.component';
import { EfaktintegracijaComponent } from './eracuni/efaktintegracija/efaktintegracija.component';
import { ErnadaccoComponent } from './eracuni/ernadacco/ernadacco.component';
import { AntivirusussComponent } from './instalacijas/antivirususs/antivirususs.component';
import { AntivrusComponent } from './instalacija/antivrus/antivrus.component';

@NgModule({
  declarations: [
    AppComponent,
    SiteHeaderComponent,
    LoginBarComponent,
    SiteLayoutComponent,
    SiteFooterComponent,
    LoginBarsComponent,
    SiteLayoutsComponent,
    SiteFootersComponent,
    SiteHeadersComponent,
    HomeComponent,
    HomesComponent,
    MainComponent,
    TopSideComponent,
    SocialSharingBarComponent,
    OnamaComponent,
    OnamadComponent,
    KontaktComponent,
    TestvodicComponent,
    VodiciComponent,
    FinComponent,
    MrezniradComponent,
    CjenikComponent,
    EconomicComponent,
    GcjenikComponent,
    KprostorComponent,
    ProfrentComponent,
    ProstorComponent,
    LayoutComponent,
    TopSideComponent,
    MainComponent,
    FinancComponent,
    FinVodicComponent,
    PlaComponent,
    PlaVodicComponent,
    MatComponent,
    MatVodicComponent,
    ProizVodicComponent,
    MatSlikeComponent,
    PlaSlikeComponent,
    FinSlikeComponent,
    RobComponent,
    MaloprComponent,
    ObrtComponent,
    OsnsrComponent,
    BlagComponent,
    PutnComponent,
    VirmComponent,
    RobVodicComponent,
    RobSlikeComponent,
    MaloprVodicComponent,
    UgostVodicComponent,
    PosVodicComponent,
    MaloprSlikeComponent,
    ObrtSlikeComponent,
    OsnsrVodicComponent,
    OsnsrSlikeComponent,
    BlagSlikeComponent,
    PutnSlikeComponent,
    VirmSlikeComponent,
    Fisk1Component,
    Fisk2Component,
    Fisk3Component,
    SvojstvaComponent,
    ModulesComponent,
    KarakComponent,
    PitodgComponent,
    ZanimljComponent,
    OsamzabludaComponent,
    IzvozComponent,
    CloudComponent,
    MongoComponent,
    LokserverComponent,
    InstalacijaComponent,
    GinstalacijaComponent,
    SqlserverComponent,
    UpgradeComponent,
    UputstvoComponent,
    MrezniradsComponent,
    CenovnikComponent,
    GcenovnikComponent,
    KprostorsComponent,
    ProstorsComponent,
    EconomicsComponent,
    MainsComponent,
    FinancsComponent,
    LayoutsComponent,
    TopSidesComponent,
    PlasComponent,
    MatsComponent,
    RobsComponent,
    MaloprsComponent,
    VirmsComponent,
    BlagsComponent,
    OsnsrsComponent,
    FinsSlikeComponent,
    FinsVodicComponent,
    PlasSlikeComponent,
    PlasVodicComponent,
    MatsSlikeComponent,
    MatsVodicComponent,
    ProizsVodicComponent,
    RobsSlikeComponent,
    RobsVodicComponent,
    MaloprsSlikeComponent,
    MaloprsVodicComponent,
    OsnsrsSlikeComponent,
    OsnsrsVodicComponent,
    BlagsSlikeComponent,
    VirmsSlikeComponent,
    DrugprihComponent,
    SvojstvasComponent,
    IzvozsComponent,
    KaraksComponent,
    OsamzabludasComponent,
    PitodgsComponent,
    ZanimljsComponent,
    CloudsComponent,
    LokserversComponent,
    MongosComponent,
    InstalacijasComponent,
    GinstalacijasComponent,
    UpgradesComponent,
    UputstvosComponent,
    OnamasComponent,
    OnamadsComponent,
    KontaktsComponent,
    StesearchsComponent,
    TrazimComponent,
    SitesearchComponent,
    EfakturesComponent,
    ApikljucComponent,
    OefakturamaComponent,
    PristupComponent,
    AdaccoefaktComponent,
    EuriComponent,
    EracuniComponent,
    OeracunimaComponent,
    EfaktintegracijaComponent,
    ErnadaccoComponent,
    AntivirusussComponent,
    AntivrusComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    JwSocialButtonsModule,
    Ng2OrderModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
