import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';
import {Dekl} from '../../dekl.service';
import {MessageComponentService} from '../../message.component.service';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-blags',
  templateUrl: './blags.component.html',
  styleUrls: ['./blags.component.css']
})
export class BlagsComponent implements OnInit {
  globv = environment;

  @ViewChild('naslovBlag', {static: false}) BlagProp: ElementRef;
  @ViewChild('naslovSadrz', {static: false}) SadrProp: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private messageService: MessageComponentService,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // this.BlagProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.titleService.setTitle('Blagajničko poslovanje | knjigovodstvo, uplate, isplate');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Blagajničko knjigovodatvo, Blagajničko poslovanje, software, Adacco, softver, uplate, isplate'},
      {name: 'description', content: 'Blagajničko poslovanje. Vodite blagajničko poslovanje svog preduzeća putem naloga za uplatu i isplatu'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }
  }

  scrollToSadrzaj(): void {
    // alert('sadr');
    this.SadrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }
}
