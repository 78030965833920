import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-osnsr-vodic',
  templateUrl: './osnsr-vodic.component.html',
  styleUrls: ['./osnsr-vodic.component.css']
})
export class OsnsrVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Osnovna sredstva | dugotrajna imovina, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Osnovna sredstva i dugotrajna imovina, software, Adacco, vodič'},
      {name: 'description', content: 'Korak po korak vodič kroz Adacco osnovna sredstva i dugotrajnu imovinu'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
