import { Component, OnInit, HostListener } from '@angular/core';
import { DbmongoService } from '../dbmongo.service';
import { Boravak } from '../boravak.service';
import { UpisiboravakService } from '../upisiboravak.service';
import { TimerService } from '../timer.service';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css']
})
export class ModulesComponent implements OnInit {

  constructor(
    private dbmongoService: DbmongoService,
    public selectedBoravak: Boravak,
    public upisiboravakservice: UpisiboravakService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.timerService.startTimer();

    const timeSpent = 0;
    this.upisiboravakservice.upisinboravak('modules', timeSpent, 1);

  }

  ngOnDestroy(): void {
    // alert(`Time spent on the page: ${timeSpent} seconds`);
    // this.upisiboravakservice.upisinboravak('page1', timeSpent);
    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;
  
    // Use async function to use await
      try {
      //  this.updejtajredak(this.selectedBoravak._id)
       this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)
  
      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    console.log('beforeunload event triggered'); // Debug statement
    alert("HostListener");

    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;

    event.preventDefault();
    (event as BeforeUnloadEvent).returnValue = '';

    // Use async function to use await
      try {
        //  this.updejtajredak(this.selectedBoravak._id)
        this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)

      } catch (error) {
        console.error('Error handling unload:', error);
      }
    }
}
