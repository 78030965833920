import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-antivrus',
  templateUrl: './antivrus.component.html',
  styleUrls: ['./antivrus.component.css']
})
export class AntivrusComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('pit1', {static: false}) pit1Prop: ElementRef;
  @ViewChild('pit2', {static: false}) pit2Prop: ElementRef;
  @ViewChild('pit3', {static: false}) pit3Prop: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Antivirus');
   
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 630, behavior: 'smooth'});
    }
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToPit1(): void {
    this.pit1Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit2(): void {
    this.pit2Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit3(): void {
    this.pit3Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
 

}
