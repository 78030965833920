import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-mreznirads',
  templateUrl: './mreznirads.component.html',
  styleUrls: ['./mreznirads.component.css']
})
export class MrezniradsComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) { }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

}
