import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-putn',
  templateUrl: './putn.component.html',
  styleUrls: ['./putn.component.css']
})
export class PutnComponent implements OnInit {

  @ViewChild('naslovPutn', {static: false}) PutnProp: ElementRef;
  @ViewChild('naslovSadrz', {static: false}) SadrProp: ElementRef;


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // this.PutnProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    this.titleService.setTitle('Putni nalozi');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Putni nalozi, Knjigovodstveni programi, software, Adacco'},
      {name: 'description', content: 'Kompletan program za putne naloge'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }
  }

  scrollToSadrzaj(): void {
    // alert('sadr');
    this.SadrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }
}
