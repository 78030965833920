import { Component, OnInit, HostListener } from '@angular/core';
import {Dekl} from '../dekl.service';
import { environment } from '../../environments/environment';

import { Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Title, Meta  } from '@angular/platform-browser';

import { DbmongoService } from '../dbmongo.service';
import { Boravak } from '../boravak.service';
import { UpisiboravakService } from '../upisiboravak.service';
import { TimerService } from '../timer.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  globv = environment;

  category: Array<any> = [{
    catnumber: 1,
    nest: [
      {
        link: 1
      },
      {
        link: 2
      }
    ]
  },
    {
      catnumber: 2,
      nest: [
        {
          link: 1
        },
        {
          link: 2
        },
      ]
    },
    {
      catnumber: 3,
      nest: [
        {
          link: 1
        },
        {
          link: 2
        }
      ]
    },
  ];

  flag: boolean = false;

  catchEvent(event) {
    this.flag = true
    console.log(event)
  }

  hide(event) {
    this.flag = false;
  }

  constructor(
    public dekl: Dekl,
     @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta,
    private dbmongoService: DbmongoService,
    public selectedBoravak: Boravak,
    public upisiboravakservice: UpisiboravakService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    console.log('Component initialized');
    // alert('Component initialized');
    this.titleService.setTitle('Adacco knjigovodstveni programi');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Knjigovodatvo, Knjigovodstveni programi, software, Adacco, programi za knjigovodstvo'},
      {name: 'description', content: 'Set poslovnih i knjigovodstvenih programa - cloud platforma'},
      {name: 'author', content: 'Adacco'}
      // ,
      // {name: 'robots', content: 'index, follow'}
    ] );


    // window.scroll(0, 0);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    this.dekl.tpage = 'home';

    this.timerService.startTimer();

    const timeSpent = 0;
    this.upisiboravakservice.upisinboravak('home', timeSpent, 1);
  }

  cjenikeconomic() {
    this.globv.cjenik = 3;
  }

  ngOnDestroy(): void {
    // alert(`Time spent on the page: ${timeSpent} seconds`);
    // this.upisiboravakservice.upisinboravak('page1', timeSpent);
    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;
  
    // Use async function to use await
      try {
      //  this.updejtajredak(this.selectedBoravak._id)
       this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)
  
      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    console.log('beforeunload event triggered'); // Debug statement
    alert("HostListener");

    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;

    event.preventDefault();
    (event as BeforeUnloadEvent).returnValue = '';

    // Use async function to use await
      try {
        //  this.updejtajredak(this.selectedBoravak._id)
        this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)

      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }
}
