import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ugost-vodic',
  templateUrl: './ugost-vodic.component.html',
  styleUrls: ['./ugost-vodic.component.css']
})
export class UgostVodicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
