import {Component, OnInit, ViewChild, ElementRef, PLATFORM_ID, Inject} from '@angular/core';
import { environment } from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-financ',
  templateUrl: './financ.component.html',
  styleUrls: ['./financ.component.css']
})
export class FinancComponent implements OnInit {
  globv = environment;

  @ViewChild('naslovFin', {static: false}) FinProp: ElementRef;
  @ViewChild('naslovSadrz', {static: false}) SadrProp: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private messageService: MessageComponentService,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // this.FinProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.titleService.setTitle('Financijsko poslovanje | obračun PDV');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Financijsko knjigovodatvo, Financijsko poslovanje, software, Adacco, temeljnice, PDV'},
      {name: 'description', content: 'Financijsko knjigovodatvo. Integriran poslovni softver za kompletno financijsko knjigovodstvo'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }

  }

  // Element.nativeElement.scrollIntoView().

  scrollToElement($element): void {
    this.FinProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'});

  }

  scrollToSadrzaj(): void {
    // alert('sadr');
    this.SadrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }
}
