import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';
import {VisibilityState} from '../../_layout/site-layout/site-layout.component';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-gcjenik',
  templateUrl: './gcjenik.component.html',
  styleUrls: ['./gcjenik.component.css']
})
export class GcjenikComponent implements OnInit {
  globv = environment;

  message: any;
  subscription: Subscription;


  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('pit1', {static: false}) pit1Prop: ElementRef;
  @ViewChild('pit2', {static: false}) pit2Prop: ElementRef;
  @ViewChild('pit3', {static: false}) pit3Prop: ElementRef;
  @ViewChild('pit4', {static: false}) pit4Prop: ElementRef;

  constructor(
    private messageService: MessageComponentService,
    @Inject(PLATFORM_ID) private platformId: object,
    public dekl: Dekl
  ) {
    this.subscription = this.messageService.getMessage().subscribe(
      message => {
        this.message = message;

        let a = dajobjectkey(message.dekl, 'tekst3');

        if ( this.globv.emit_to_page === 'gcjenik') {
          // alert(a);

          if (a === 'economic') {
            this.scrollToPit1();
          }
          if (a === 'profesional') {
            this.scrollToPit2();
          }
          if (a === 'rent') {
            this.scrollToPit3();
          }
          if (a === 'complete') {
            this.scrollToPit4();
          }

        }
      });
  }

  ngOnInit() {
    // this.pit3Prop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // setTimeout(() => this.gasiheader(), 1000);
  }

  scrollToVrh(): void {
    // alert('sadr');
    // this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    // window.scroll(0, 0);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  scrollToPit1(): void {
    this.pit1Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit2(): void {
    this.pit2Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit3(): void {
    this.pit3Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit4(): void {
    this.pit4Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }



}



function dajobjectkey(tobject: object, tkey: string) {
  let result = '';

  for (let p in tobject) {
    if ( tobject.hasOwnProperty(p) ) {
      // result += p + ' , ' + message.dekl[p];
      if (p === tkey) {result = tobject[p]; }
    }
  }
  return result;
}
