import {Component, HostListener, OnInit} from '@angular/core';
import { environment } from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {VisibilityState} from '../site-layout/site-layout.component';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
  doslaporuka: string = 'jos nista ...';

  tporuka = '.';
  globv = environment;

  message: any;
  subscription: Subscription;

  showdropdown1 = true;
  isopen: false;
  testvisible = true;

  navbarCollapsed = false;
  navbarOpen = false;

  public tsearchstring: string = '';
  name: string = '';


  menu00visible = true;
  menu01visible = true;
  menu02visible = true;
  menu03visible = true;
  menu04visible = true;
  menu05visible = true;

   constructor(
    private router: Router,
    private messageService: MessageComponentService,
    public dekl: Dekl
  ) {
    this.subscription = this.messageService.getMessage().subscribe(
      message => {
        this.message = message;

        let a = dajobjectkey(message.dekl, 'tekst3');

        if ( this.globv.emit_to_page === 'sitelayout') {
          // alert(a);
          this.doslaporuka = 'Side je čuo1! ' + a;
          this.tporuka = ' čuo! ' + a;

          if (a === 'gasiheader') {
            this.toggleNavbar();
          }
          this.globv.emit_to_page = 'xx';
        }
      });
  }

  ngOnInit() {
  }


  navigajUpgrade() {
    // alert('aaa');
    let n = Date.now();

    const url = '/upgrade?id=' + n.toString();
    this.router.navigateByUrl(url);
  }

  navigajGinstalacija() {
    // alert('baa');
    let n = Date.now();

    const url = '/ginstalacija?id=' + n.toString();
    this.router.navigateByUrl(url);
  }



  menu000ver() {
    this.menu00visible = true;
    this.menu01visible = false;
    this.menu02visible = false;
    this.menu03visible = false;
    this.menu04visible = false;
  }

  menu010ver() {
    this.menu00visible = false;
    this.menu01visible = true;
    this.menu02visible = false;
    this.menu03visible = false;
    this.menu04visible = false;
  }

  menu020ver() {
    this.menu00visible = false;
    this.menu01visible = false;
    this.menu02visible = true;
    this.menu03visible = false;
    this.menu04visible = false;
  }
  // menu02eave() {
  //   this.menu01visible = true;
  // }

  menu030ver() {
    this.menu01visible = false;
    this.menu02visible = false;
    this.menu03visible = true;
    this.menu04visible = false;
  }
  // menu03eave() {
  //   this.menu03visible = true;
  // }

  menu040ver() {
    this.menu00visible = false;
    this.menu01visible = false;
    this.menu02visible = false;
    this.menu03visible = false;
    this.menu04visible = true;
  }

  menu00click() {
    // this.toggleNavbar();
    if (window.innerWidth >  500) {
      this.menu00visible = false;
    }
  }
  menu01click() {
    // alert('menu01');
    // this.toggleNavbar();

    if (this.menu01visible === false) {
      this.menu01visible = true;
    } else {
      this.menu01visible = false;
    }

    // this.menu01visible = false;
    this.sendMessage2();
  }
  menu02click() {
    if (this.menu02visible === false) {
      this.menu02visible = true;
    } else {
      this.menu02visible = false;
    }
  }
  menu03click() {
    // this.toggleNavbar();
    if (this.menu03visible === false) {
      this.menu03visible = true;
    } else {
      // if (window.innerWidth >  500) {
      this.menu03visible = false;
      // }
    }
  }
  menu04click() {
    if (this.menu04visible === false) {
      this.menu04visible = true;
    } else {
      this.menu04visible = false;
    }
  }

  finclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'fin';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  placlick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'pla';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  matclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'mat';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  maloprclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'malopr';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  obrtclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'obrt';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  osnsrclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'osnsr';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  robclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'rob';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  blagclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'blag';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  putnclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'putn';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }
  virmclick() {
    // this.toggleNavbar();
    this.dekl.tekst3 = 'virm';
    this.globv.emit_to_page = 'topside';
    this.messageService.sendMessage2(this.dekl);
    this.toggleNavbar();
  }

  karakclick() {
    this.toggleNavbar();
  }
  pitodgclick() {
    this.toggleNavbar();
  }
  zanimljclick() {
    this.toggleNavbar();
  }
  cloudclick() {
    this.toggleNavbar();
  }

  onamaclick() {
    this.toggleNavbar();
  }
  kontaktclick() {
    this.toggleNavbar();
  }
  menucjenikclick() {
    this.toggleNavbar();
  }

  sendMessage2(): void {
    // alert('Šaljem 3');
    // this.dekl.tekst3 = 'Naredba from Header3 Component to Page3 Component!';
    this.dekl.tekst3 = '';
    this.globv.emit_to_page = 'topside';
    // send message to subscribers via observable subject
    this.messageService.sendMessage2(this.dekl);

    // this.tsearchstring  = '';
  }


  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    // console.log(e);
    this.showdropdown1 = true;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  public sajtsearch(): any {
    //  alert('Š33');
    this.toggleNavbar();
    // this.router.navigateByUrl('/sitesearch');
    const a = this.tsearchstring;

    // alert('to je: ' + this.tsearchstring);

    if (this.tsearchstring === null || this.tsearchstring  === undefined) {
      return;
    } else {
      // alert('da da ' + this.tsearchstring);
      //
      this.globv.tsearchstring = this.tsearchstring;
      this.sendMessageSrch();
    }

    // this.router.navigateByUrl('/sitesearch');
    this.router.navigate(['sitesearch', this.globv.tsearchstring]);
  }

  sendMessageSrch(): void {
    // alert('Šaljem 3');
    // this.dekl.tekst3 = 'Naredba from Header3 Component to Page3 Component!';
    this.dekl.tekst3 = this.tsearchstring;
    this.globv.emit_to_page = 'sitesearch';
    // send message to subscribers via observable subject
    this.messageService.sendMessage2(this.dekl);

    this.tsearchstring  = '';
  }
}



function dajobjectkey(tobject: object, tkey: string) {
  let result = '';

  for (let p in tobject) {
    if ( tobject.hasOwnProperty(p) ) {
      // result += p + ' , ' + message.dekl[p];
      if (p === tkey) {result = tobject[p]; }
    }
  }
  return result;
}

