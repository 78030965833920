import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';

@Component({
  selector: 'app-onama',
  templateUrl: './onama.component.html',
  styleUrls: ['./onama.component.css']
})
export class OnamaComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit() {
    // window.scroll(0, 0);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

}
