import { Injectable } from '@angular/core';
import { DbmongoService } from './dbmongo.service';
import { Boravak } from './boravak.service';
import { Subscription } from 'rxjs';
import { forkJoin, Observable, of, throwError  } from 'rxjs';
import { switchMap, finalize, tap, catchError   } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpisiboravakService {
  tdata: any;

  constructor(
    private dbmongoService: DbmongoService,
    public selectedBoravak: Boravak,
  ) { }


  upisinboravak(tpaga:string, ttrajanje: number, ths: number): void {
    // alert('dajdatafromip');
  
    let ipAddress: string = '';
  
    this.dbmongoService.getIpAddress().pipe(
      switchMap(result1 => {
        ipAddress = result1.ip.split(':').pop();
        // this.processIpAddress(ipAddress); // Call a function to handle ipAddress
        return this.dbmongoService.getLocation(ipAddress).pipe(
          catchError(error => {
            console.error('Error in second service call:', error);
            // Return an observable representing the error state
            return throwError(error); 
          })
        );
      })
    )
    .subscribe({
      next: (result2: any) => {
        // Log the result to see if it's as expected
        // console.log('Result:', result2);
  
        const rawDataString = result2.rawData;
        const rawData = JSON.parse(rawDataString);
  
        const country = rawData.country.names.en;
        const city = rawData.subdivisions[1].names.en;
  
        // alert('IP: ' + ipAddress + '\nDržava: ' + country + '\nGrad: ' + city);

        this.dodajredak(tpaga, ipAddress, country, city, ttrajanje, ths)
      },
      error: error => {
        // This should log errors from the observable chain
        console.error('Subscription error:', error);
      }
    });
  }
  
  dodajredak(tpage: string, tipAddress: string, tcountry: string, tcity: string, ttrajanje: number, ths: number): void {
    // alert ("dod");
    let nidb = -1;

    const tobservable = forkJoin([
       this.dbmongoService.GetMaxValue('Boravci', 'boravci', 'Idb'),
    ]);

    tobservable.subscribe((data: any) => {

      let dat1 = data[0];
      let odg1 = dat1[0].tekst1;
      let nidb0 = dat1[0].broj1;
      if (odg1 === 'Ok') {
        nidb = nidb0 + 1;
      } else {
        if (odg1 === 'Error' || nidb == -1) {
          alert( 'Ne mogu naći novi idb.');
          // this.tmessage = 'Ne mogu naći novi Red.br.'
          // this.tmessage2 = this.tdummy.tekst2;
          // this.messageWindow.title('Greška');
          // this.messageWindow.position('center');
          // this.messageWindow.open();
          return;
        }
      }
        this.dbmongoService.AddRow('Boravci', 'boravci')
        .subscribe(data => {
      
          this.tdata = data;  
      
          if (this.tdata[0].tekst1=='Ok') {
      
            this.selectedBoravak._id = this.tdata[0].tekst3;
            // this.selectedZaposleni.Ime = 'konj23';
            // this.selectedBoravak.Stranica = 'page1';
            // alert('ok');

            this.selectedBoravak.Stranica = tpage
            this.selectedBoravak.Idb = nidb
            let daten = new Date;
            this.selectedBoravak.DatumPrijave = daten;
            this.selectedBoravak.ipadresa = tipAddress;
            this.selectedBoravak.drzava = tcountry;
            this.selectedBoravak.grad = tcity;
            this.selectedBoravak.trajanje = ttrajanje;
            this.selectedBoravak.hs = ths;

            this.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id);

          }
        });
      });
  }

  updejtajredaksvc(tselectedBoravak: Boravak, tt_id: string): any {

    if (tt_id == undefined) { return;}
  
    // this.dbmongoService.UpdateRow(this.ttImeMdb, 'zaposleni', t_id, this.selectedZaposleni)
    this.dbmongoService.UpdateRow('Boravci', 'boravci', tt_id, tselectedBoravak)
    .subscribe(data => {

      this.tdata = data;  

       if (this.tdata[0].tekst1=='Ok') {
        //  alert('ok update')
        // this.dekl.tekst3 = 'ok';
        // this.dekl.object1 = this.selectedFirmaCh;
        // this.globv.emit_to_page = 'listafirmi';
        // this.messageService.sendMessage2(this.dekl);
      } else {
        alert('greška2 update');
        // this.tmessage = 'Greška.';
        // this.tmessage2 =  this.tdata[0].tekst2;;
        // this.messageWindow.position('center');
        // this.messageWindow.open();
       }
    });
    
  }

  
}
