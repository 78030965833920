import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-osnsr-slike',
  templateUrl: './osnsr-slike.component.html',
  styleUrls: ['./osnsr-slike.component.css']
})
export class OsnsrSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('lista', {static: false}) ListaProp: ElementRef;
  @ViewChild('amort', {static: false}) AmortProp: ElementRef;
  @ViewChild('kartica', {static: false}) KarticaProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {

    this.titleService.setTitle('Osnovna sredstva | dugotrajna imovina, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Osnovna sredstva, dugotrajna imovina, software, Adacco, slike'},
      {name: 'description', content: 'Osnovna sredstva - dugotrajna imovina. Neke slike iz Adacco obračuna osnovnih sredstava, dugotrajne imovine'},
      {name: 'author', content: 'Adacco'}
    ] );

  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToLista(): void {
    this.ListaProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToAmort(): void {
    this.AmortProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToKartica(): void {
    this.KarticaProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
