import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-zanimljs',
  templateUrl: './zanimljs.component.html',
  styleUrls: ['./zanimljs.component.css']
})
export class ZanimljsComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 630, behavior: 'smooth'});
    }
  }

}
