import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-mreznirad',
  templateUrl: './mreznirad.component.html',
  styleUrls: ['./mreznirad.component.css']
})
export class MrezniradComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) { }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

}
