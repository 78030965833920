import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-proiz-vodic',
  templateUrl: './proiz-vodic.component.html',
  styleUrls: ['./proiz-vodic.component.css']
})
export class ProizVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Proizvodnja | materijalno knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Proizvodnja, materijalno knjigovodstvo, software, Adacco, vodič'},
      {name: 'description', content: 'Proizvodnja. Korak po korak vodič kroz Adacco Proizvodnju '},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
