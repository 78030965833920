import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-mat-vodic',
  templateUrl: './mat-vodic.component.html',
  styleUrls: ['./mat-vodic.component.css']
})
export class MatVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Materijalno knjigovodatvo | knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Materijalno knjigovodatvo, software, Adacco, vodič'},
      {name: 'description', content: 'Materijalno knjigovodatvo. Korak po korak vodič kroz Adacco Materijalno knjigovodatvo'},
      {name: 'author', content: 'Adacco'}
    ] );

  }

}
