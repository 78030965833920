import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-onamads',
  templateUrl: './onamads.component.html',
  styleUrls: ['./onamads.component.css']
})
export class OnamadsComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;

  constructor() { }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
