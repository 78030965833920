import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-plas-slike',
  templateUrl: './plas-slike.component.html',
  styleUrls: ['./plas-slike.component.css']
})
export class PlasSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('zaposl', {static: false}) ZaposlProp: ElementRef;
  @ViewChild('pojobr', {static: false}) PojobrProp: ElementRef;
  @ViewChild('ukobr', {static: false}) UkobrProp: ElementRef;
  @ViewChild('joppda', {static: false}) JoppdaProp: ElementRef;
  @ViewChild('joppdb', {static: false}) JoppdbProp: ElementRef;
  @ViewChild('ispisjoppd', {static: false}) IspisjoppdProp: ElementRef;


  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Obračun plata i kadrovska evidencija, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Obračun plata i kadrovska evidencija, software, Adacco, slike'},
      {name: 'description', content: 'Obračun plata i kadrovska evidencija. Neke slike iz Adacco Obračuna plata i kadrovske evidencije' },
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToZaposl(): void {
    this.ZaposlProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPojobr(): void {
    this.PojobrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToUkobr(): void {
    this.UkobrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToJoppda(): void {
    this.JoppdaProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToJoppdb(): void {
    this.JoppdbProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIspisjoppd(): void {
    this.IspisjoppdProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
