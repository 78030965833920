import { Component, OnInit } from '@angular/core';
import {Dekl} from '../../dekl.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-osamzabluda',
  templateUrl: './osamzabluda.component.html',
  styleUrls: ['./osamzabluda.component.css']
})
export class OsamzabludaComponent implements OnInit {

  constructor(
    public dekl: Dekl,
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta

  ) { }

  ngOnInit() {
    if ( this.dekl.tpage === 'home') {
      // window.scroll(0, 500);
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo({top: 620, behavior: 'smooth'});
      }
    }
    this.dekl.tpage = 'karak';
    this.titleService.setTitle('Osam zabluda o knjigovodstvenim programima');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Osam zabluda o knjigovodstvenim programima, software, Adacco'},
      {name: 'description', content: 'Osam zabluda o knjigovodstvenim programima.'},
      {name: 'author', content: 'Adacco'}
    ] );


  }

}
