import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-putn-slike',
  templateUrl: './putn-slike.component.html',
  styleUrls: ['./putn-slike.component.css']
})
export class PutnSlikeComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('putnal', {static: false}) PutnalProp: ElementRef;
  @ViewChild('putrn', {static: false}) PutrnProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta

  ) { }

  ngOnInit() {
    this.titleService.setTitle('Putni nalozi');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Putni nalozi slike, Knjigovodstveni programi, software, Adacco'},
      {name: 'description', content: 'Neke slike iz programa za putne naloge'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToPutnal(): void {
    this.PutnalProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPutrn(): void {
    this.PutrnProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
