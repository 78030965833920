import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-kontakts',
  templateUrl: './kontakts.component.html',
  styleUrls: ['./kontakts.component.css']
})
export class KontaktsComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;

  constructor() { }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
