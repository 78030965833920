import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-sharing-bar',
  templateUrl: './social-sharing-bar.component.html',
  styleUrls: ['./social-sharing-bar.component.css']
})
export class SocialSharingBarComponent implements OnInit {

  url = 'http://www.adacco.com';
  text = `Adacco software | Poslovni i knjigovodstveni programi`;
  // imageUrl = 'http://jasonwatmore.com/_content/images/jason.jpg';
  imageUrl = 'assets/images/laptop1.jpg';

  constructor() { }

  ngOnInit() {
  }

}
