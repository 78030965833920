import { Component, OnInit, HostListener } from '@angular/core';
import {Router} from '@angular/router';

import { DbmongoService } from '../dbmongo.service';
import { Boravak } from '../boravak.service';
import { UpisiboravakService } from '../upisiboravak.service';
import { TimerService } from '../timer.service';

@Component({
  selector: 'app-instalacijas',
  templateUrl: './instalacijas.component.html',
  styleUrls: ['./instalacijas.component.css']
})
export class InstalacijasComponent implements OnInit {

  constructor(
    private router: Router,
    private dbmongoService: DbmongoService,
    public selectedBoravak: Boravak,
    public upisiboravakservice: UpisiboravakService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.timerService.startTimer();

    const timeSpent = 0;
    this.upisiboravakservice.upisinboravak('instalacijas', timeSpent, 1);

    
  }

  navigajUpgrade() {
    // alert('aaa');
    let n = Date.now();

    const url = '/upgrades?id=' + n.toString();
    this.router.navigateByUrl(url);
  }

  navigajGinstalacija() {
    // alert('baa');
    let n = Date.now();

    const url = '/ginstalacijas?id=' + n.toString();
    this.router.navigateByUrl(url);
  }

  ngOnDestroy(): void {
    // alert(`Time spent on the page: ${timeSpent} seconds`);
    // this.upisiboravakservice.upisinboravak('page1', timeSpent);
    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;
  
    // Use async function to use await
      try {
      //  this.updejtajredak(this.selectedBoravak._id)
       this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)
  
      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    console.log('beforeunload event triggered'); // Debug statement
    alert("HostListener");

    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;

    event.preventDefault();
    (event as BeforeUnloadEvent).returnValue = '';

    // Use async function to use await
      try {
        //  this.updejtajredak(this.selectedBoravak._id)
        this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)

      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }


}
