import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-fins-vodic',
  templateUrl: './fins-vodic.component.html',
  styleUrls: ['./fins-vodic.component.css']
})
export class FinsVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Finansijsko poslovanje | knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Finansijsko knjigovodatvo, Finansijsko poslovanje, software, Adacco, temeljnice, vodič'},
      {name: 'description', content: 'Finansijsko poslovanje. Korak po korak vodič kroz Adacco Finansijsko poslovanje'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
