import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-rob',
  templateUrl: './rob.component.html',
  styleUrls: ['./rob.component.css']
})
export class RobComponent implements OnInit {
  globv = environment;

  @ViewChild('naslovRob', {static: false}) RobProp: ElementRef;
  @ViewChild('naslovSadrz', {static: false}) SadrProp: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private messageService: MessageComponentService,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // this.RobProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // setTimeout(() => this.gasiheader(), 1000);

    this.titleService.setTitle('Robno knjigovodatvo | Veleprodaja');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Robno knjigovodatvo, Veleprodaja, Knjigovodstveni programi, software, Adacco, računi, fakturiranje'},
      {name: 'description', content: 'Kompletan program za vođenje robnog knjigovodstva ili veleprodaje'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }
  }

  scrollToSadrzaj(): void {
    // alert('sadr');
    this.SadrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }
}
