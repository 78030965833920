import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-fins-slike',
  templateUrl: './fins-slike.component.html',
  styleUrls: ['./fins-slike.component.css']
})
export class FinsSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('knjufa', {static: false}) KnjufaProp: ElementRef;
  @ViewChild('knjiz', {static: false}) KnjizProp: ElementRef;
  @ViewChild('ispis', {static: false}) IspisProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Finansijsko poslovanje | knjigovodstvo, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Finansijsko knjigovodatvo, Finansijsko poslovanje, software, Adacco, knjiženje, slike'},
      {name: 'description', content: 'Finansijsko poslovanje. Neke slike iz Adacco Finansijskog poslovanja'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToKnjufa(): void {
    this.KnjufaProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToKnjiz(): void {
    this.KnjizProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIspis(): void {
    this.IspisProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }


}
