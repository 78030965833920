import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';
@Component({
  selector: 'app-maloprs-slike',
  templateUrl: './maloprs-slike.component.html',
  styleUrls: ['./maloprs-slike.component.css']
})
export class MaloprsSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('kalk', {static: false}) KalkProp: ElementRef;
  @ViewChild('ispis', {static: false}) IspisProp: ElementRef;
  @ViewChild('rn', {static: false}) RnProp: ElementRef;
  @ViewChild('trgknj', {static: false}) TrgknjProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {
  }

  ngOnInit() {
    this.titleService.setTitle('Maloprodajno poslovanje | knjigovodstvo, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Maloprodajno knjigovodatvo, Maloprodajno poslovanje, software, Adacco, fiskalizacija, slike'},
      {name: 'description', content: 'Maloprodajno poslovanje. Neke slike iz Adacco Maloprodajnog poslovanja'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToKalk(): void {
    this.KalkProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToIspis(): void {
    this.IspisProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToRn(): void {
    this.RnProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToTrgknj(): void {
    this.TrgknjProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
