import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';


@Component({
  selector: 'app-pos-vodic',
  templateUrl: './pos-vodic.component.html',
  styleUrls: ['./pos-vodic.component.css']
})
export class PosVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('POS | maloprodaja knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'POS, maloprodaja, software, Adacco, vodič'},
      {name: 'description', content: 'POS. Korak po korak vodič kroz Adacco POS '},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
