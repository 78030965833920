import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apikljuc',
  templateUrl: './apikljuc.component.html',
  styleUrls: ['./apikljuc.component.css']
})
export class ApikljucComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
