import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-plas-vodic',
  templateUrl: './plas-vodic.component.html',
  styleUrls: ['./plas-vodic.component.css']
})
export class PlasVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Obračun plata i kadrovska evidencija | knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Obračun plata i kadrovska evidencija, software, Adacco, vodič'},
      {name: 'description', content: 'Obračun plata i kadrovska evidencija. Korak po korak vodič kroz Adacco Obračun plata '},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
