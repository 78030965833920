import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-ginstalacijas',
  templateUrl: './ginstalacijas.component.html',
  styleUrls: ['./ginstalacijas.component.css']
})
export class GinstalacijasComponent implements OnInit {

  url: string = 'assets/pages/sr/ukinst.html';
  urlSafe: SafeResourceUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    let n = Date.now();
    this.url = this.url + '?id=' + n.toString();

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 300, behavior: 'smooth'});
    }

    this.titleService.setTitle('Ukupna instalacija Adacco programa');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Download, Preuzimanje, instalacija, software, Adacco, svi programi za knjigovodstvo'},
      {name: 'description', content: 'Ukupna instalacija Adacco programa - cloud platforma'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
