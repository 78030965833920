import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-euri',
  templateUrl: './euri.component.html',
  styleUrls: ['./euri.component.css']
})
export class EuriComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    this.titleService.setTitle('Adacco programi Prelazak na euro');
    this.metaTagService.addTags( [ ///updateTag
      {name: 'keywords', content: 'Prelazak na euro, Euro, software, Adacco'},
      {name: 'description', content: 'Prelazak na euro, Euro, software, Adacco'},
      {name: 'author', content: 'Adacco'}
      // ,
      // {name: 'robots', content: 'index, follow'}
     ] );

  }

}
