import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-fin-vodic',
  templateUrl: './fin-vodic.component.html',
  styleUrls: ['./fin-vodic.component.css']
})
export class FinVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Financijsko poslovanje | knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Financijsko knjigovodatvo, Financijsko poslovanje, software, Adacco, temeljnice, vodič'},
      {name: 'description', content: 'Financijsko poslovanje. Korak po korak vodič kroz Adacco Financijsko poslovanje'},
      {name: 'author', content: 'Adacco'},
      {name: 'robots', content: 'index, follow'}
    ] );
  }

}
