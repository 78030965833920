import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {Dekl} from '../../dekl.service';
import {MessageComponentService} from '../../message.component.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-top-side',
  templateUrl: './top-side.component.html',
  styleUrls: ['./top-side.component.css']
})
export class TopSideComponent implements OnInit {
  doslaporuka: string = '.';

  tporuka = '.';
  globv = environment;

  message: any;
  subscription: Subscription;

  naslovStyle0 = {
    // 'background-color': 'lime',
    // 'font-weight': 'normal'
  }

  naslovStyle2 = {
    // 'background-color': 'white',
    'color':  'brown',
    'font-weight': 'bold'
  }

  naslovStyleFin = this.naslovStyle0;
  naslovStylePla = this.naslovStyle0;
  naslovStyleMat = this.naslovStyle0;
  naslovStyleRob = this.naslovStyle0;
  naslovStyleMalopr = this.naslovStyle0;
  naslovStyleObrt = this.naslovStyle0;
  naslovStyleOsnsr = this.naslovStyle0;
  naslovStyleBlag = this.naslovStyle0;
  naslovStylePutn = this.naslovStyle0;
  naslovStyleVirm = this.naslovStyle0;
  naslovStyleFisk = this.naslovStyle0;


  constructor(
    private messageService: MessageComponentService,
    public dekl: Dekl
  ) {
    this.subscription = this.messageService.getMessage().subscribe(
      message => {
        this.message = message;

        let a = dajobjectkey(message.dekl, 'tekst3');

        if ( this.globv.emit_to_page === 'topside') {
          // alert(a);
          this.doslaporuka = 'Side je čuo! ' + a;
          this.tporuka = '';  //' čuo! ' + a;

          this.naslovStyleFin = this.naslovStyle0;
          this.naslovStylePla = this.naslovStyle0;
          this.naslovStyleMat = this.naslovStyle0;
          this.naslovStyleRob = this.naslovStyle0;
          this.naslovStyleMalopr = this.naslovStyle0;
          this.naslovStyleObrt = this.naslovStyle0;
          this.naslovStyleOsnsr = this.naslovStyle0;
          this.naslovStyleBlag = this.naslovStyle0;
          this.naslovStylePutn = this.naslovStyle0;
          this.naslovStyleVirm = this.naslovStyle0;
          this.naslovStyleFisk = this.naslovStyle0;

          if (a === 'fin') {
            this.naslovStyleFin = this.naslovStyle2;
          }
          if (a === 'pla') {
            this.naslovStylePla = this.naslovStyle2;
          }
          if (a === 'mat') {
            this.naslovStyleMat = this.naslovStyle2;
          }
          if (a === 'rob') {
            this.naslovStyleRob = this.naslovStyle2;
          }
          if (a === 'malopr') {
            this.naslovStyleMalopr = this.naslovStyle2;
          }
          if (a === 'obrt') {
            this.naslovStyleObrt = this.naslovStyle2;
          }
          if (a === 'osnsr') {
            this.naslovStyleOsnsr = this.naslovStyle2;
          }
          if (a === 'blag') {
            this.naslovStyleBlag = this.naslovStyle2;
          }
          if (a === 'putn') {
            this.naslovStylePutn = this.naslovStyle2;
          }
          if (a === 'virm') {
            this.naslovStyleVirm = this.naslovStyle2;
          }
          if (a === 'fisk') {
            this.naslovStyleFisk = this.naslovStyle2;
          }
        }

        // setTimeout(() => this.gasiheader(), 1000);

      });
  }

  ngOnInit() {
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;

    if ( this.dekl.tmodul === 1) {
      this.naslovStyleFin = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 2) {
      this.naslovStylePla = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 3) {
      this.naslovStyleMat = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 4) {
      this.naslovStyleRob = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 5) {
      this.naslovStyleMalopr = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 6) {
      this.naslovStyleObrt = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 7) {
      this.naslovStyleOsnsr = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 8) {
      this.naslovStyleBlag = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 9) {
      this.naslovStylePutn = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 10) {
      this.naslovStyleVirm = this.naslovStyle2;
    }
    if ( this.dekl.tmodul === 11) {
      this.naslovStyleFisk = this.naslovStyle2;
    }
  }


  mainClick() {
    this.dekl.tmodul = 0;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
    // location.reload();
  }

  finClick() {
    this.dekl.tmodul = 1;
    this.naslovStyleFin = this.naslovStyle2;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  plaClick() {
    this.dekl.tmodul = 2;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle2;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  matClick() {
    this.dekl.tmodul = 3;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle2;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  robClick() {
    this.dekl.tmodul = 4;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle2;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  maloprClick() {
    this.dekl.tmodul = 5;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle2;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  obrtClick() {
    this.dekl.tmodul = 6;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle2;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  osnsrClick() {
    this.dekl.tmodul = 7;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle2;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  blagClick() {
    this.dekl.tmodul = 8;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle2;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  putnClick() {
    this.dekl.tmodul = 9;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle2;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  virmClick() {
    this.dekl.tmodul = 10;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle2;
    this.naslovStyleFisk = this.naslovStyle0;
  }
  fiskClick() {
    this.dekl.tmodul = 11;
    this.naslovStyleFin = this.naslovStyle0;
    this.naslovStylePla = this.naslovStyle0;
    this.naslovStyleMat = this.naslovStyle0;
    this.naslovStyleRob = this.naslovStyle0;
    this.naslovStyleMalopr = this.naslovStyle0;
    this.naslovStyleObrt = this.naslovStyle0;
    this.naslovStyleOsnsr = this.naslovStyle0;
    this.naslovStyleBlag = this.naslovStyle0;
    this.naslovStylePutn = this.naslovStyle0;
    this.naslovStyleVirm = this.naslovStyle0;
    this.naslovStyleFisk = this.naslovStyle2;
  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }

}

function dajobjectkey(tobject: object, tkey: string) {
  let result = '';

  for (let p in tobject) {
    if ( tobject.hasOwnProperty(p) ) {
      // result += p + ' , ' + message.dekl[p];
      if (p === tkey) {result = tobject[p]; }
    }
  }
  return result;
}

