// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tsearchstring: '',
  NOVI: false,
  DEMO: false,
  PONISTI: true,
  cjenik: 0,
  tIMEMDB: 'AdcUniversity',
  tSlikeDir: 'UploadedImages',
  emit_to_page: '',
  THOST: 'www.adacco.com',
  moji_data: {}

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
