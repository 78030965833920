import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {Dekl} from '../../dekl.service';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cloud',
  templateUrl: './cloud.component.html',
  styleUrls: ['./cloud.component.css']
})
export class CloudComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('strukt', {static: false}) StruktProp: ElementRef;
  @ViewChild('demo', {static: false}) DemoProp: ElementRef;
  @ViewChild('modul', {static: false}) ModulProp: ElementRef;
  @ViewChild('prostor', {static: false}) ProstorProp: ElementRef;
  @ViewChild('novi', {static: false}) NoviProp: ElementRef;
  @ViewChild('stari', {static: false}) StariProp: ElementRef;
  @ViewChild('ispisi', {static: false}) IspisiProp: ElementRef;
  @ViewChild('neogr', {static: false}) NeogrProp: ElementRef;
  @ViewChild('uputstva', {static: false}) UputstvaProp: ElementRef;
  @ViewChild('pouzd', {static: false}) PouzdProp: ElementRef;


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router
  ) { }

  ngOnInit() {

    // this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    this.titleService.setTitle('Adacco programi Cloud');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Cloud, Cloud knjigovodstveni, software, Adacco'},
      {name: 'description', content: 'Cloud Adacco programi. Simbolične cijene, vrhunski softver'},
      {name: 'author', content: 'Adacco'}
      // ,
      // {name: 'robots', content: 'index, follow'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 630, behavior: 'smooth'});
    }

  }

  scrollToVrh(): void {
    // alert('sadr');

    // window.scrollTo({ top: 0, behavior: 'smooth' });
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToStrukt(): void {
    this.StruktProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToDemo(): void {
    this.DemoProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToModul(): void {
    this.ModulProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToProstor(): void {
    this.ProstorProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToNovi(): void {
    this.NoviProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToStari(): void {
    this.StariProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIspisi(): void {
    this.IspisiProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToNeogr(): void {
    this.NeogrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToUputstva(): void {
    this.UputstvaProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPouzd(): void {
    this.PouzdProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  upisimodul(tmdl) {
    // switch (tmdl) {
    //   case 1:
    //     this.dekl.tmodul = 1;
    //     break;
    //   case 2:
    //     this.dekl.tmodul = 1;
    //     break;
    //
    // }
    this.dekl.tmodul = tmdl;
  }

  clickmongo(): void {
    // alert('mongo');
    const url = 'mongo';
    this.router.navigateByUrl(url);
  }
}
