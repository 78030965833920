import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pristup',
  templateUrl: './pristup.component.html',
  styleUrls: ['./pristup.component.css']
})
export class PristupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
