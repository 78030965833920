import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-onamas',
  templateUrl: './onamas.component.html',
  styleUrls: ['./onamas.component.css']
})
export class OnamasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
