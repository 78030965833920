// export const mojsajt = [
//   {id: 1, name: 'Superman gjdujzd', link: 'aaa'},
//   {id: 2, name: 'Batman ttrzeruz', link: 'bbb'},
//   {id: 5, name: 'BatGirl', link: 'ccc'},
//   {id: 3, name: 'Robin', link: 'ddd'},
//   {id: 4, name: 'Flash', link: 'eee'}
// ];


export const mojsajt = [
  {
    'id': '1',
    'name': 'Novak Djokovic',
    'content': 'Novak Djokovic ion of  ATP Ft in the ATP rankings for over 230 weeks. ',
    'link': 'novak'
  },
  {
    'id': '2',
    'name': 'Ariana Grande',
    'content': 'Nebela gyo6',
    'link': 'ariana'
  },
  {
    'id': '3',
    'name': 'GTA 5',
    'content': 'Nebela gyo6',
    'link': 'ngxscrolling'
  },
  {
    'id': '4',
    'name': 'Far Cry 31',
    'content': 'Nebela 78123',
    'link': 'virtlist'
  },
  {
    'id': '5',
    'name': 'GTA San Andreas',
    'content': 'Nebela huh',
    'link': 'Action'
  },
  {
    'id': '6',
    'name': 'Hitman',
    'content': 'Nebela fb856',
    'link': 'Action'
  },
  {
    'id': '7',
    'name': 'NFS MW',
    'content': 'Nebela debela',
    'link': 'Sport'
  },
  {
    'id': '8',
    'name': 'Fifa 16',
    'content': 'dtysdtvurtvruur',
    'link': 'Sport'
  },
  {
    'id': '9',
    'name': 'NFS Sen 2',
    'content': 'fu7fb5 bgtyhknoum',
    'link': 'Sport'
  },
  {
    'id': '10',
    'name': 'Witcher Assasins on King',
    'content': 'gjvud7v5s7 k tdb86f 4556',
    'link': 'Adventure'
  }
]




