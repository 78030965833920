import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-blags-slike',
  templateUrl: './blags-slike.component.html',
  styleUrls: ['./blags-slike.component.css']
})
export class BlagsSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('ispl', {static: false}) IsplProp: ElementRef;
  @ViewChild('ispis', {static: false}) IspisProp: ElementRef;
  @ViewChild('blagizv', {static: false}) BlagizvProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Blagajničko poslovanje | knjigovodstvo, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Blagajničko knjigovodatvo, Blagajničko poslovanje, software, Adacco, softver, uplate, isplate, slike'},
      {name: 'description', content: 'Blagajničko poslovanje. Neke slike iz Adacco blagajničkog poslovanja'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToIspl(): void {
    this.IsplProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIspis(): void {
    this.IspisProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToBlagizv(): void {
    this.BlagizvProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
