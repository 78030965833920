import { Component, HostListener, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, pairwise, takeUntil, throttleTime } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {environment} from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';

import { Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';


export enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

export enum Direction {
  None = 'None',
  Up = 'Up',
  Down = 'Down',
  Vrh = 'Vrh'
}

@Component({
  selector: 'app-site-layouts',
  templateUrl: './site-layouts.component.html',
  styleUrls: ['./site-layouts.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state(VisibilityState.Visible, style({
        transform: 'translateY(0)'
      })),
      state(VisibilityState.Hidden, style({
        transform: 'translateY(-64px)' // adjust this to the height of your header
      })),
      transition(`${VisibilityState.Visible} => ${VisibilityState.Hidden}`, animate('250ms')),
      transition(`${VisibilityState.Hidden} => ${VisibilityState.Visible}`, animate('250ms'))
    ])
  ]
})
export class SiteLayoutsComponent implements AfterViewInit, OnDestroy, OnInit {
  doslaporuka: string = '.';

  tporuka = '.';
  globv = environment;

  message: any;
  subscription: Subscription;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  isHeader1Visible = VisibilityState.Visible;
  // isHeader2Visible = VisibilityState.Hidden;
  slideHeader2InAtPosition = 30;

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    // window.removeEventListener('scroll', this.scrollEvent, true);

  }

  constructor(
    private messageService: MessageComponentService,
    @Inject(PLATFORM_ID) private platformId: object,
    public dekl: Dekl
  ) {

    this.subscription = this.messageService.getMessage().subscribe(
      message => {
        this.message = message;

        let a = dajobjectkey(message.dekl, 'tekst3');

        if ( this.globv.emit_to_page === 'sitelayout') {
          // alert(a);
          this.doslaporuka = 'Side je čuo! ' + a;
          this.tporuka = ' čuo! ' + a;

          let aa = window.scrollY;


          if (a === 'gasiheader') {
            if (aa >= 30) {
              this.isHeader1Visible = VisibilityState.Hidden;
            }
          }
          // this.globv.emit_to_page = 'xx';

        }
      });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const scroll$ = fromEvent(window, 'scroll').pipe( // if the scroll events happen on your window you could use 'window' instead of 'content' here
        throttleTime(10),
        map(() => window.pageYOffset), // if you used 'window' above replace 'content.scrollTop' with 'window.pageYOffset'
        pairwise(),
        map(([y1, y2]): Direction => {
          let value = Direction.None;
          if (y2 < y1) {
            value = Direction.Up;
            if (y2 < 50) {
              value = Direction.Vrh;
            }
          } else if (y2 > this.slideHeader2InAtPosition) {
            value = Direction.Down;
          } else {
            value = Direction.None;
          }
          return value;
        }),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      );

      scroll$.subscribe(dir => {

        if (dir === Direction.Down) {
          // console.log('scrolling down', window.scrollY);
          this.isHeader1Visible = VisibilityState.Hidden;

        } else if (dir === Direction.Vrh) {
          this.isHeader1Visible = VisibilityState.Visible;

        } else {
          // console.log('scrolling up', window.scrollTop);
          // console.log('scrolling up', window.scrollY);
          this.isHeader1Visible = VisibilityState.Visible;
        }
      });
    }
  }
}


function dajobjectkey(tobject: object, tkey: string) {
  let result = '';

  for (let p in tobject) {
    if ( tobject.hasOwnProperty(p) ) {
      // result += p + ' , ' + message.dekl[p];
      if (p === tkey) {result = tobject[p]; }
    }
  }
  return result;
}

