import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Dummy {
  tekst1 = '';
  tekst2 = '';
  tekst3 = '';
  tekst4 = '';
  tekst5 = '';
  tekst6 = '';
  tekst7 = '';
  tekst8 = '';
  tekst9 = '';
  tekst10 = '';
  tekst11 = '';
  tekst12 = '';
  tekst13 = '';
  tekst14 = '';
  tekst15 = '';
  tekst16 = '';
  tekst17 = '';
  tekst18 = '';
  tekst19 = '';
  tekst20 = '';

  broj1 = 0;
  broj2 = 0;
  broj3 = 0;
  broj4 = 0;
  broj5 = 0;
  broj6 = 0;
  broj7 = 0;
  broj8 = 0;
  broj9 = 0;
  broj10 = 0;
  broj11 = 0;
  broj12 = 0;
  broj13 = 0;
  broj14 = 0;
  broj15 = 0;
  broj16 = 0;
  broj17 = 0;
  broj18 = 0;
  broj19 = 0;
  broj20 = 0;
}

