import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-rob-slike',
  templateUrl: './rob-slike.component.html',
  styleUrls: ['./rob-slike.component.css']
})
export class RobSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('listart', {static: false}) ListartProp: ElementRef;
  @ViewChild('kalk', {static: false}) KalkProp: ElementRef;
  @ViewChild('rn', {static: false}) RnProp: ElementRef;
  @ViewChild('skl', {static: false}) SklProp: ElementRef;
  @ViewChild('rekap', {static: false}) RekapProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Robno knjigovodatvo | Veleprodaja | Slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Robno knjigovodatvo, Veleprodaja, Slike, Knjigovodstveni programi, software, Adacco, računi, fakturiranje'},
      {name: 'description', content: 'Neke slike - program za vođenje robnog knjigovodstva ili veleprodaje'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollListart(): void {
    this.ListartProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToKalk(): void {
    this.KalkProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToRn(): void {
    this.RnProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToSkl(): void {
    this.SklProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToRekap(): void {
    this.RekapProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
