import { Component, OnInit, ViewChild, AfterViewInit, Renderer2 , ElementRef, Inject, Injectable} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Dekl} from '../../dekl.service';
import {DbService} from '../../db.service';

@Component({
  selector: 'app-login-bars',
  templateUrl: './login-bars.component.html',
  styleUrls: ['./login-bars.component.css']
})


export class LoginBarsComponent implements OnInit {

  constructor(
    private dbService: DbService,
    public dekl: Dekl,
    private router: Router
  ) { }

  ngOnInit() {
  }


  Croatian_click(): void {
    localStorage.setItem('country', 'hrv');
    this.router.navigate(['home']);
  }

}
