import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';
import { catchError} from 'rxjs/operators';

import {Dummy} from './dummy.service';
import {Observable, of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbmongoService {

  constructor(
    private http: HttpClient
  ) { }

  bok(): string {
    return 'bokbok1'
  }

  hello(): Observable<Dummy> {
    const turl = 'http://' + '/www.adacco.com'  + ':4003/mnbases/helloworld';

    return this.http
      .get<Dummy>(turl)
      .pipe(
        catchError(error => {
          let errorMsg: string;
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          } else {
            errorMsg = 'greska na serveru';
          }

          alert(errorMsg);
          return throwError(errorMsg);
          // return tdummy;
        })
      );
  }


  AddRow(tdatabase: string, ttablica: string): Observable<Dummy[]> {
    let turl = 'http://' + '/www.adacco.com' + ':4003/mnbases/addRow';
    turl = turl + '/' + tdatabase;
    turl = turl + '/' + ttablica;

    const a = this.http.get<Dummy[]>(turl);
    return this.  http.get<Dummy[]>(turl);
  }

  UpdateRow(tdatabase: string, ttablica: string, tid: string, tobject: Record<string, any>): Observable<Dummy[]> {
    // UpdateRow(tdatabase: string, ttablica: string, tid: string, tobject: Record<string, any>): Observable<any[]> {
      let turl = 'http://' + '/www.adacco.com'  + ':4003/mnbases/updateRedak';
      turl = turl + '/' + tdatabase;
      turl = turl + '/' + ttablica;
      turl = turl + '/' + tid;
  
      let objstring='';
      objstring = objstring + '?';
      let i=0;
      Object.keys(tobject).forEach(function(key) {
        
        // console.log(key, tobject[key]);
        objstring = objstring + key;
        objstring = objstring + '=';
        objstring = objstring + tobject[key];
        objstring = objstring + '&';
        i=i+1;
      });
      objstring = objstring.substring(0, objstring.length - 1);
  
      turl = turl + '/' + objstring;
  
  
      const a = this.http.get<Dummy[]>(turl);
      // return this.http.get<Korisnik[]>(turl);
  
      return this.http
        .get<Dummy[]>(turl)
        .pipe(
          catchError(error => {
            let errorMsg: string;
            if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
            } else {
              errorMsg = 'server update greska';
            }
  
            alert(errorMsg);
            return throwError(errorMsg);
            // return tdummy;
          })
        );
    }
  
    GetMaxValue(tdatabase: string, ttablica: string, tfield: string): Observable<Dummy[]> {

      // let turl = 'http://' + 'localhost'  + ':4003/mnbases/getMaxFieldValue';
      let turl = 'http://www.adacco.com:4003/mnbases/getMaxFieldValue';
      turl = turl + '/' + tdatabase;
      turl = turl + '/' + ttablica;
      turl = turl + '/' + tfield;
     
      const a = this.http.get<Dummy[]>(turl);
      // return this.http.get<Korisnik[]>(turl);
  
      return this.http
        .get<Dummy[]>(turl)
        .pipe(
          catchError(error => {
            let errorMsg: string;
            if (error.error instanceof ErrorEvent) {
              errorMsg = `Error: ${error.error.message}`;
            } else {
              errorMsg = 'greska kod servera';
            }
  
            alert(errorMsg);
            return throwError(errorMsg);
            // return tdummy;
          })
        );
    }


   
    getIpAddress(): Observable<any> {
      let turl = 'http://www.adacco.com:4003/mnbases/getip';

      let a = this.http.get<any>(turl);
      return this.http.get<any>(turl);
    }
    
    getDataFromIp(): Observable<any> {
      let turl = 'https://api.findip.net/178.221.67.222/?token=8f80c5b93b3b453b96e523cf70a419cb';
      return this.http.get<any>(turl);
    }

    // getLocation(ipAddress: string): Observable<{ rawData: string }> {
    //   // let turl = 'http://' + 'localhost'  + ':4003/mnbases';
    //   let turl = 'http://www.adacco.com'  + ':4003/mnbases';
    //   const url = `${turl}/get-location/${ipAddress}`;
    //   return this.http.get<{ rawData: string }>(url);
    // }
    
    getLocation(ipAddress: string): Observable<{ rawData: string }> {
      const turl = 'http://www.adacco.com:4003/mnbases';
      const url = `${turl}/get-location/${ipAddress}`;
      
      return this.http.get<{ rawData: string }>(url).pipe(
        catchError(error => {
          console.error('Error in HTTP request:', error);
          return throwError(error);
        })
      );
    }
    
    // async getIpAddressSync(): Promise<any> {
    //   const turl = 'http://www.adacco.com:4003/mnbases/getip';
    //   const response = await this.http.get<any>(turl).toPromise();
    //   return response;
    // }
  
    // async getLocationSync(ipAddress: string): Promise<{ rawData: string }> {
    //   const turl = 'http://www.adacco.com:4003/mnbases';
    //   const url = `${turl}/get-location/${ipAddress}`;
  
    //   try {
    //     const response = await this.http.get<{ rawData: string }>(url).toPromise();
    //     return response;
    //   } catch (error) {
    //     console.error('Error in HTTP request:', error);
    //     throw error;
    //   }
    // }
}


