import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-efaktintegracija',
  templateUrl: './efaktintegracija.component.html',
  styleUrls: ['./efaktintegracija.component.css']
})
export class EfaktintegracijaComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 730, behavior: 'smooth'});
    }
  }

 
}
