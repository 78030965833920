import { Component, OnInit, ViewChild, AfterViewInit, Renderer2 , ElementRef, Inject, Injectable} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Student } from '../../student.service';
import { Dekl} from '../../dekl.service';
// import { LOCAL_STORAGE, StorageService } from 'angular-webstorage-service';
import {DbService} from '../../db.service';

@Component({
  selector: 'app-login-bar',
  templateUrl: './login-bar.component.html',
  styleUrls: ['./login-bar.component.css'],
})
export class LoginBarComponent implements OnInit {

  constructor(
    // @Inject(LOCAL_STORAGE) private storage: StorageService,
    private dbService: DbService,
    public dekl: Dekl,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.init_country();
  }


  Serbian_click(): void {
    localStorage.setItem('country', 'srb');
    this.router.navigate(['homes']);
    // this.router.navigate(['finv']);

    // const url = '/ginstalacija';
    // this.router.navigateByUrl(url);

  }

  English_click(): void {
    // this.storage.set('country', 'srb');
    // this.router.navigate(['finv']);
  }

  // let tcntr = this.storage.get('country');

  init_country(): void {
    if (localStorage.getItem('country') != null) {
      let tcntr = localStorage.getItem('country');
      if (tcntr === 'srb') {
        this.router.navigate(['homes']);
      }
    }
  }

}
