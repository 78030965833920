import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-virms-slike',
  templateUrl: './virms-slike.component.html',
  styleUrls: ['./virms-slike.component.css']
})
export class VirmsSlikeComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('virm', {static: false}) VirmProp: ElementRef;
  @ViewChild('zbrnal', {static: false}) ZbrnalProp: ElementRef;

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Virmani | knjigovodstvo, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Virmani,  poslovanje, software, Adacco, slike'},
      {name: 'description', content: 'Virmani. Neke slike iz Adacco Virmana'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToVirm(): void {
    this.VirmProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToZbrnal(): void {
    this.ZbrnalProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
