import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-obrt',
  templateUrl: './obrt.component.html',
  styleUrls: ['./obrt.component.css']
})
export class ObrtComponent implements OnInit {
  globv = environment;

  @ViewChild('naslovObrt', {static: false}) ObrtProp: ElementRef;
  @ViewChild('naslovSadrz', {static: false}) SadrProp: ElementRef;


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private messageService: MessageComponentService,
    public dekl: Dekl
  ) { }

  ngOnInit() {
    // this.ObrtProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    // setTimeout(() => this.gasiheader(), 1000);

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }

  }

  scrollToSadrzaj(): void {
    // alert('sadr');
    this.SadrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }
}
