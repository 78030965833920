import {Component, ElementRef, OnInit, ViewChild, HostListener} from '@angular/core';
import { environment } from '../../environments/environment';
import {MessageComponentService} from '../message.component.service';
import {Dekl} from '../dekl.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';

import { Title, Meta  } from '@angular/platform-browser';

import { DbmongoService } from '../dbmongo.service';
import { Boravak } from '../boravak.service';
import { UpisiboravakService } from '../upisiboravak.service';
import { TimerService } from '../timer.service';

@Component({
  selector: 'app-cenovnik',
  templateUrl: './cenovnik.component.html',
  styleUrls: ['./cenovnik.component.css']
})
export class CenovnikComponent implements OnInit {
  globv = environment;

  constructor(
    private messageService: MessageComponentService,
    @Inject(PLATFORM_ID) private platformId: object,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta,
    private dbmongoService: DbmongoService,
    public selectedBoravak: Boravak,
    public upisiboravakservice: UpisiboravakService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Adacco programi Cenovnik');
    this.metaTagService.addTags( [ ///updateTag
      {name: 'keywords', content: 'Cenovnik, Cene knjigovodstveni, software, Adacco'},
      {name: 'description', content: 'Cenovnik Adacco programa. Simbolične cene, vrhunski softver'},
      {name: 'author', content: 'Adacco'}
      // ,
      // {name: 'robots', content: 'index, follow'}
     ] );

    if (this.globv.cjenik === 3) {
      this.saljieconomic();
      this.globv.cjenik = 0;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }

    this.timerService.startTimer();

    const timeSpent = 0;
    this.upisiboravakservice.upisinboravak('cenovnik', timeSpent, 1);

  }

  saljieconomic() {
    // alert("econ"):
    this.dekl.tekst3 = 'economic';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  saljiprofesional() {
    // alert("econ"):
    this.dekl.tekst3 = 'profesional';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  saljirent() {
    // alert("econ"):
    this.dekl.tekst3 = 'rent';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  saljicomplete() {
    // alert("econ"):
    this.dekl.tekst3 = 'complete';
    this.globv.emit_to_page = 'gcjenik';
    this.messageService.sendMessage2(this.dekl);
  }

  ngOnDestroy(): void {
    // alert(`Time spent on the page: ${timeSpent} seconds`);
    // this.upisiboravakservice.upisinboravak('page1', timeSpent);
    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;
  
    // Use async function to use await
      try {
      //  this.updejtajredak(this.selectedBoravak._id)
       this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)
  
      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    console.log('beforeunload event triggered'); // Debug statement
    alert("HostListener");

    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;

    event.preventDefault();
    (event as BeforeUnloadEvent).returnValue = '';

    // Use async function to use await
      try {
        //  this.updejtajredak(this.selectedBoravak._id)
        this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)

      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }

}
