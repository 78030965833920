import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor() { }
  
  private startTime: number;

  startTimer(): void {
    this.startTime = Date.now();
  }

  getTimeElapsed(): number {
    return Math.floor((Date.now() - this.startTime) / 1000); // Convert to seconds
  }

}
