import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { environment } from '../../../environments/environment';
import {MessageComponentService} from '../../message.component.service';
import {Dekl} from '../../dekl.service';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-mats',
  templateUrl: './mats.component.html',
  styleUrls: ['./mats.component.css']
})
export class MatsComponent implements OnInit {
  globv = environment;
  x;

  @ViewChild('naslovMat', {static: false}) MatProp: ElementRef;
  @ViewChild('naslovSadrz', {static: false}) SadrProp: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private messageService: MessageComponentService,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta

  ) { }

  ngOnInit() {
    // this.MatProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

    this.titleService.setTitle('Materijalno knjigovodatvo');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Materijalno knjigovodatvo, Knjigovodstveni programi, software, Adacco, prijemnice, izdavanje'},
      {name: 'description', content: 'Kompletan program za vođenje materijalnog knjigovodstva'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }
  }


  scrollToSadrzaj(): void {
    // alert('sadr');
    this.SadrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  gasiheader() {
    this.dekl.tekst3 = 'gasiheader';
    this.globv.emit_to_page = 'sitelayout';
    this.messageService.sendMessage2(this.dekl);
  }

}
