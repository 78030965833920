import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oefakturama',
  templateUrl: './oefakturama.component.html',
  styleUrls: ['./oefakturama.component.css']
})
export class OefakturamaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
