import { Component, OnInit, HostListener } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

import { DbmongoService } from '../../dbmongo.service';
import { Boravak } from '../../boravak.service';
import { UpisiboravakService } from '../../upisiboravak.service';
import { TimerService } from '../../timer.service';

@Component({
  selector: 'app-uputstvos',
  templateUrl: './uputstvos.component.html',
  styleUrls: ['./uputstvos.component.css']
})
export class UputstvosComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta,
    private dbmongoService: DbmongoService,
    public selectedBoravak: Boravak,
    public upisiboravakservice: UpisiboravakService,
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Uputstvo za instalaciju - Adacco programi');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Instalacija, mongo server, software, Adacco, cloud, Adacco'},
      {name: 'description', content: 'Poslovni i knjigovodstveni programi - Uputstvo za instalaciju '},
      {name: 'author', content: 'Adacco'}
    ] );


    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }

    this.timerService.startTimer();

    const timeSpent = 0;
    this.upisiboravakservice.upisinboravak('uputstvo', timeSpent, 1);

  }

  ngOnDestroy(): void {
    // alert(`Time spent on the page: ${timeSpent} seconds`);
    // this.upisiboravakservice.upisinboravak('page1', timeSpent);
    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;
  
    // Use async function to use await
      try {
      //  this.updejtajredak(this.selectedBoravak._id)
       this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)
  
      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event): void {
    console.log('beforeunload event triggered'); // Debug statement
    alert("HostListener");

    const timeSpent = this.timerService.getTimeElapsed();
    this.selectedBoravak.trajanje = timeSpent;

    event.preventDefault();
    (event as BeforeUnloadEvent).returnValue = '';

    // Use async function to use await
      try {
        //  this.updejtajredak(this.selectedBoravak._id)
        this.upisiboravakservice.updejtajredaksvc(this.selectedBoravak, this.selectedBoravak._id)

      } catch (error) {
        console.error('Error handling unload:', error);
      }
  }
}
