import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {Dekl} from '../../dekl.service';
import { Title, Meta  } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';


@Component({
  selector: 'app-karaks',
  templateUrl: './karaks.component.html',
  styleUrls: ['./karaks.component.css']
})
export class KaraksComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('strukt', {static: false}) StruktProp: ElementRef;
  @ViewChild('demo', {static: false}) DemoProp: ElementRef;
  @ViewChild('modul', {static: false}) ModulProp: ElementRef;
  @ViewChild('samood', {static: false}) SamoodProp: ElementRef;
  @ViewChild('integr', {static: false}) IntegrProp: ElementRef;
  @ViewChild('arhiv', {static: false}) ArhivProp: ElementRef;
  @ViewChild('ispisi', {static: false}) IspisiProp: ElementRef;
  @ViewChild('neogr', {static: false}) NeogrProp: ElementRef;
  @ViewChild('uputstva', {static: false}) UputstvaProp: ElementRef;
  @ViewChild('pouzd', {static: false}) PouzdProp: ElementRef;


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    public dekl: Dekl,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    if ( this.dekl.tpage === 'home') {
      // window.scroll(0, 500);
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      // window.scrollTo({ top: 500, behavior: 'smooth' });

    }
    this.dekl.tpage = 'karak';

    this.titleService.setTitle('Karakteristike Adacco programi ');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Karakteristike, Adacco knjigovodstveni software, Adacco'},
      {name: 'description', content: 'Karakteristike Adacco programa. Simbolične cene, vrhunski softver'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 630, behavior: 'smooth'});
    }
  }

  scrollToVrh(): void {
    // alert('sadr');

    // window.scrollTo({ top: 0, behavior: 'smooth' });
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToStrukt(): void {
    this.StruktProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToDemo(): void {
    this.DemoProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToModul(): void {
    this.ModulProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToSamood(): void {
    this.SamoodProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIntegr(): void {
    this.IntegrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToArhiv(): void {
    this.ArhivProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToIspisi(): void {
    this.IspisiProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToNeogr(): void {
    this.NeogrProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToUputstva(): void {
    this.UputstvaProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPouzd(): void {
    this.PouzdProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  upisimodul(tmdl) {
    // switch (tmdl) {
    //   case 1:
    //     this.dekl.tmodul = 1;
    //     break;
    //   case 2:
    //     this.dekl.tmodul = 1;
    //     break;
    //
    // }
    this.dekl.tmodul = tmdl;
  }
}
