import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-maloprs-vodic',
  templateUrl: './maloprs-vodic.component.html',
  styleUrls: ['./maloprs-vodic.component.css']
})
export class MaloprsVodicComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Maloprodajno poslovanje | knjigovodstvo, korak po korak vodič');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Maloprodajno knjigovodatvo, Maloprodajno poslovanje, software, Adacco, vodič'},
      {name: 'description', content: 'Maloprodajno poslovanje. Korak po korak vodič kroz Adacco Maloprodajno poslovanje'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
