import {Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-pitodg',
  templateUrl: './pitodg.component.html',
  styleUrls: ['./pitodg.component.css']
})
export class PitodgComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('pit1', {static: false}) pit1Prop: ElementRef;
  @ViewChild('pit2', {static: false}) pit2Prop: ElementRef;
  @ViewChild('pit3', {static: false}) pit3Prop: ElementRef;
  @ViewChild('pit4', {static: false}) pit4Prop: ElementRef;
  @ViewChild('pit5', {static: false}) pit5Prop: ElementRef;
  @ViewChild('pit6', {static: false}) pit6Prop: ElementRef;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Adacco pitanja i odgovori');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Adacco Pitanja i odgovori, Cijene knjigovodstveni, software, Adacco'},
      {name: 'description', content: 'Adacco Pitanja i odgovori, Simbolične cijene, vrhunski softver'},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 630, behavior: 'smooth'});
    }

  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToPit1(): void {
    this.pit1Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit2(): void {
    this.pit2Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit3(): void {
    this.pit3Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit4(): void {
    this.pit4Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit5(): void {
    this.pit5Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPit6(): void {
    this.pit6Prop.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
