import { Component, OnInit } from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-fisk2',
  templateUrl: './fisk2.component.html',
  styleUrls: ['./fisk2.component.css']
})
export class Fisk2Component implements OnInit {

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Fiskalizacija u Adacco programima');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Fiskalizacija, Knjigovodstveni programi, software, Adacco, programi za knjigovodstvo'},
      {name: 'description', content: 'Fiskalizacija u Adacco programima'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

}
