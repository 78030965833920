import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-onamad',
  templateUrl: './onamad.component.html',
  styleUrls: ['./onamad.component.css']
})
export class OnamadComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  goto(animal: string): void {
    // this.router.navigate(['trazim', animal]);
    this.router.navigate(['trazim', 'xxyyzz']);
    // this.router.navigate(['home']);
  }

}
