import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Dekl {
  tmodul: number = 0;

  role: string = 'test';

  tbroj: number = 0;
  tidstud: number = 0;
  tekst1 = 'ok1';
  tekst2 = '';
  tekst3 = '';
  tekst4 = '';

  ulogovan = false;
  // constructor() { }

  tpage = 'home';
}
