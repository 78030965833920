import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css']
})
export class KontaktComponent implements OnInit {

  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;

  constructor() {
  }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start'});
  }

  fb(e) {
    alert("fb");
    // this.dekl.tekst3 = 'economic';
    // this.globv.emit_to_page = 'gcjenik';
    // this.messageService.sendMessage2(this.dekl);

    // let url = 'www.google.com';
    // e.preventDefault();
    // let facebookWindow = window.open(
    //   'https://www.google.com' +
    //   'facebook-popup',
    //   'height=350,width=600'
    // );
    // if (facebookWindow.focus) {
    //   facebookWindow.focus();
    // }
    // return false;
   // '' window.open("https://www.w3schools.com");


    // let pageURL = 'https://www.w3schools.com';
    let pageURL = 'https://www.facebook.com/adacco.net';
    let title = 'Naslov';

    let w = 1000;
    let h = 700;
    // let ws = w.toString();
    // let hs = h.toString();

    let left = (screen.width/2)-(w/2);
    let top = (screen.height/2)-(h/2);
    // let lefts = left.toString();
    // let tops= top.toString();


    // let targetWin = window.open (pageURL, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,titlebar=no, width=700, height=400, top=100, left=200');
    // targetWin.focus();
   let targetWin = window.open (pageURL, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,titlebar=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

  }

  //function dajobjectkey(tobject: object, tkey: string)

  // function  PopupCenter(pageURL: string, title: string, w,h) {
  //   var left = (screen.width/2)-(w/2);
  //   var top = (screen.height/2)-(h/2);
  //   var targetWin = window.open (pageURL, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,titlebar=no, width='+w+', height='+h+', top='+top+', left='+left);
  //   targetWin.focus();
  // }
  ''
  // https://www.facebook.com/sharer/sharer.php?u='+ 'Use Referral code ->' + this.userData.referCode + '   to join the newest fantasy sports platform' +  url + '. Earn extra points along with lots of exciting prizes. Don’t delay, join the match today! url',
  // 'https://www.facebook.com/sharer/sharer.php?u='+ 'Use Referral code ->' + this.userData.referCode + '   to join the newest fantasy sports platform' +  url + '. Earn extra points along with lots of exciting prizes. Don’t delay, join the match today! url',

}
