import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-efaktures',
  templateUrl: './efaktures.component.html',
  styleUrls: ['./efaktures.component.css']
})
export class EfakturesComponent implements OnInit {
  @ViewChild('list', {static: false}) ListProp: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  scrollToList(): void {
    this.ListProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
