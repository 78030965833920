import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-prostor',
  templateUrl: './prostor.component.html',
  styleUrls: ['./prostor.component.css']
})
export class ProstorComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;


  constructor() { }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

}
