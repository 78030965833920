import { Component, OnInit } from '@angular/core';
// import { Routes } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-trazim',
  templateUrl: './trazim.component.html',
  styleUrls: ['./trazim.component.css']
})
export class TrazimComponent implements OnInit {

  subscribedParam = 'initial value';
  snapshotParam = 'initial value2';

  // constructor(private _routeParams: RouteParams) {
  //   var queryParam = this._routeParams.get('q');
  // }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.subscribedParam = params.get('animal');
    });

    this.snapshotParam = this.route.snapshot.paramMap.get('animal');

  }


}
