import { Component, OnInit } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

// https://stackblitz.com/edit/angular-iframe-src?file=src%2Fapp%2Fapp.component.ts

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit {

  // currentUrl: SafeResourceUrl;
  // url: "http://www.adacco.com/assets/pages/upgrade.html";

  // url: string = 'http://www.adacco.com/assets/pages/upgrade.html';
  url: string = 'assets/pages/upgrade.html';
  urlSafe: SafeResourceUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    let n = Date.now();
    this.url = this.url + '?id=' + n.toString();
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);


    this.titleService.setTitle('Upgrade - nove verzije Adacco softver instalacija');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Upgrade, Knjigovodstveni programi, software, Najnovije verzije, inačice, programi za knjigovodstvo'},
      {name: 'description', content: 'Update (upgrade) - instalacija najnovijih verzija Adacco softver '},
      {name: 'author', content: 'Adacco'}
    ] );

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 350, behavior: 'smooth'});
    }

    // this.currentUrl = 'http://www.adacco.com/assets/pages/upgrade.html';


  }

  refresh(): void {
    window.location.reload();
  }
}
