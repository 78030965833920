import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-eracuni',
  templateUrl: './eracuni.component.html',
  styleUrls: ['./eracuni.component.css']
})
export class EracuniComponent implements OnInit {
  @ViewChild('list', {static: false}) ListProp: ElementRef;
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;

  constructor() { }

  ngOnInit() {
    this.VrhProp.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollToList(): void {
    this.ListProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
