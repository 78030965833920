import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, PLATFORM_ID} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-mains',
  templateUrl: './mains.component.html',
  styleUrls: ['./mains.component.css'],
  animations: [

    trigger('changeRobDivSize', [
      state('initialrob', style({
        height: '200px'
      })),
      state('finalrob', style({
        // height: '450px'
        height: '100%'
      })),
      transition('initialrob=>finalrob', animate('500ms'))
    ]),
    trigger('changePlaDivSize', [
      state('initialpla', style({
        height: '170px'
      })),
      state('finalpla', style({
        // height: '450px'
        height: '100%'
      })),
      transition('initialpla=>finalpla', animate('200ms'))
    ]),
    trigger('changeMaloprDivSize', [
      state('initialmalopr', style({
        height: '170px'
      })),
      state('finalmalopr', style({
        // height: '450px'
        height: '100%'
      })),
      transition('initialmalopr=>finalmalopr', animate('200ms'))
    ]),
    trigger('changeMatDivSize', [
      state('initialmat', style({
        height: '170px'
      })),
      state('finalmat', style({
        // height: '450px'
        height: '100%'
      })),
      transition('initialmat=>finalmat', animate('200ms'))
    ])

  ]
})
export class MainsComponent implements OnInit {

  @ViewChild('Adacco', {static: false}) LocAdacco: ElementRef;
  @ViewChild('Adacco', {static: false}) LocVrh: ElementRef;

  currentStateRob = 'initialrob';
  currentStatePla = 'initialpla';
  currentStateMalopr = 'initialmalopr';
  currentStateMat = 'initialmat';

  showrobvise = true;
  showplavise = true;
  showmaloprvise = true;
  showmatvise = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    // window.scroll(0, 0);
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }

    this.titleService.setTitle('Adacco knjigovodstveni programi - moduli');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Knjigovodatvo, Knjigovodstveni programi, software, Adacco, programi za knjigovodstvo'},
      {name: 'description', content: 'Svaki od modula može raditi zasebno, a ako ih se instalira više, tada rade u integriranoj verziji.'},
      {name: 'author', content: 'Adacco'}
    ] );

  }

  scrollToAdacco(): void {
    this.LocAdacco.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  robVise() {
    this.currentStateRob = this.currentStateRob === 'initialrob' ? 'finalrob' : 'initialrob';
    this.showrobvise = false;
  }
  plaVise() {
    this.currentStatePla = this.currentStatePla === 'initialpla' ? 'finalpla' : 'initialpla';
    this.showplavise = false;
  }
  maloprVise() {
    this.currentStateMalopr = this.currentStateMalopr === 'initialmalopr' ? 'finalmalopr' : 'initialmalopr';
    this.showmaloprvise = false;
  }
  matVise() {
    this.currentStateMat = this.currentStateMat === 'initialmat' ? 'finalmat' : 'initialmat';
    this.showmatvise = false;
  }

}
