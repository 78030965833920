import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mongo',
  templateUrl: './mongo.component.html',
  styleUrls: ['./mongo.component.css']
})
export class MongoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
