import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-robs-vodic',
  templateUrl: './robs-vodic.component.html',
  styleUrls: ['./robs-vodic.component.css']
})
export class RobsVodicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
