import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Title, Meta  } from '@angular/platform-browser';

@Component({
  selector: 'app-mat-slike',
  templateUrl: './mat-slike.component.html',
  styleUrls: ['./mat-slike.component.css']
})
export class MatSlikeComponent implements OnInit {
  @ViewChild('naslovVrh', {static: false}) VrhProp: ElementRef;
  @ViewChild('izdatica', {static: false}) IzdProp: ElementRef;
  @ViewChild('primka', {static: false}) PrimProp: ElementRef;
  @ViewChild('listproiz', {static: false}) ListproizProp: ElementRef;
  @ViewChild('norm', {static: false}) NormProp: ElementRef;
  @ViewChild('radnal', {static: false}) RadnalProp: ElementRef;
  @ViewChild('promet', {static: false}) PrometProp: ElementRef;


  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Materijalno knjigovodatvo | knjigovodstvo, slike');
    this.metaTagService.addTags( [
      {name: 'keywords', content: 'Materijalno knjigovodatvo, Materijalno poslovanje, software, Adacco, slike'},
      {name: 'description', content: 'Materijalno knjigovodatvo. Neke slike iz Adacco Materijalnog knjigovodstva'},
      {name: 'author', content: 'Adacco'}
    ] );
  }

  scrollToVrh(): void {
    // alert('sadr');
    this.VrhProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});

  }

  scrollToIzd(): void {
    this.IzdProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPrimka(): void {
    this.PrimProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToListproiz(): void {
    this.ListproizProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToNorm(): void {
    this.NormProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

  scrollToRadnal(): void {
    this.RadnalProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
  scrollToPromet(): void {
    this.PrometProp.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
